var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"pl-0"},[_vm._v(_vm._s(_vm.$t("visible_sections")))]),_c('permissions-editor-section',{attrs:{"icon":"mdi-home-outline","available":_vm.available,"item":_vm.item,"permission":"dashboard","mandatory":"","single":_vm.is_hotel,"section_permissions":[
      'dashboard_rms',
      'dashboard_rms_hotelsdot',
      'dashboard_rate'
    ]}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-hand-coin-outline","available":_vm.available,"item":_vm.item,"permission":"rms","section_permissions":[
      'rms_strategy',
      'rms_strategy_grid',
      'rms_availability',
      'rms_year_sales',
      'rms_year_sales_grid',
      'rms_sales',
      'rms_segment',
      'rms_channel',
      'rms_roomtype',
      'rms_prices_evo',
      'rms_pricing',
      'rms_special_rates',
      'rms_offers',
      'rms_compare_prev_years',
      'rms_production_mode'
    ]}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-hand-coin-outline","available":_vm.available,"item":_vm.item,"permission":"rms_hotelsdot","section_permissions":[
      'rms_strategy_hotelsdot',
      'rms_availability_hotelsdot',
      'rms_sales_hotelsdot',
      'rms_segment_hotelsdot',
      'rms_channel_hotelsdot',
      'rms_roomtype_hotelsdot',
      'rms_prices_evo_hotelsdot',
      'rms_pricing_hotelsdot',
      'rms_special_rates_hotelsdot',
      'rms_offers_hotelsdot',
      'rms_compare_prev_years',
      'rms_production_mode'
    ]}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-hand-coin-outline","available":_vm.available,"item":_vm.item,"permission":"rms_axel","section_permissions":[
      'rms_strategy_axel',
      'rms_year_sales_axel',
      'rms_availability',
      'rms_sales',
      'rms_segment',
      'rms_channel',
      'rms_roomtype',
      'rms_pricing',
      'rms_compare_prev_years',
      'rms_production_mode'
    ]}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-hand-coin-outline","available":_vm.available,"item":_vm.item,"permission":"rms_clicktotravel","section_permissions":[
      'rms_strategy_clicktotravel',
      'rms_year_sales_clicktotravel',
      'rms_availability',
      'rms_sales',
      'rms_segment',
      'rms_channel',
      'rms_roomtype',
      'rms_pricing',
      'rms_compare_prev_years',
      'rms_production_mode',
      'rms_production_config'
    ]}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-chart-timeline-variant","available":_vm.available,"item":_vm.item,"permission":"revenue","section_permissions":['revenue_channel', 'revenue_pickup']}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-table-chair","available":_vm.available,"item":_vm.item,"permission":"revenuerestaurant"}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-wallet-bifold-outline","available":_vm.available,"item":_vm.item,"permission":"expense"}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-comment-multiple-outline","available":_vm.available,"item":_vm.item,"permission":"reputation","section_permissions":[
      'reputation_booking',
      'reputation_tripadvisor',
      'reputation_expedia',
      'reputation_googlehotelads',
      'reputation_tenedor',
      'reputation_survey'
    ]}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-bed-king-outline","available":_vm.available,"item":_vm.item,"permission":"rate","section_permissions":[
      'rate_web',
      'rate_channelmanager_tab',
      'parity',
      'channelmanager'
    ]}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-api","available":_vm.available,"item":_vm.item,"permission":"connectapi","section_permissions":['realtime']}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-abacus","available":_vm.available,"item":_vm.item,"permission":"budget","section_permissions":['budget_daily', 'budget_channels']}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-cog-outline","available":_vm.available,"item":_vm.item,"permission":"settings","section_permissions":[
      'customermanagement',
      'hotelmanagement',
      'accounting',
      'usermanagement',
      'trialmanagement',
      'requestsmanagement',
      'apistatus',
      'roomtypemanagement',
      'personal',
      'restaurantmanagement',
      'calendarmanagement',
      'eventsmanagement',
      'metrics',
      'revenue_import_manual',
      'daily_import_manual',
      'channel_import_manual',
      'reservations_import_manual',
      'revenue_import_launch',
      'segmentschannels'
    ],"superuser_permissions":[
      'customermanagement',
      'trialmanagement',
      'requestsmanagement',
      'apistatus'
    ]}}),_c('permissions-editor-section',{attrs:{"icon":"mdi-tools","available":_vm.available,"item":_vm.item,"permission":"tools","section_permissions":[
      'highlights',
      'export_pdf',
      'veetal_assistant',
      'automations'
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }