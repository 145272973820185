<template>
  <v-row>
    <v-col cols="4" class="py-0">
      <v-select
        v-model="item.settings.revenue_mode"
        :items="items_revenue_mode"
        :label="$t('revenue_mode_label')"
        :rules="[v => !!v || $t('required')]"
        outlined
        rounded
        dense
      ></v-select>
    </v-col>
    <v-col cols="4" class="py-0">
      <v-select
        v-model="item.settings.room_revenue_with_board"
        :items="items_room_revenue_with_board"
        :label="$t('room_revenue_with_board_label')"
        :rules="[v => !!v || $t('required')]"
        outlined
        rounded
        dense
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "a-hotel-configuration-revenue",
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  },
  computed: {
    items_revenue_mode() {
      return [
        { text: this.$t("net"), value: "net" },
        { text: this.$t("gross"), value: "gross" }
      ];
    },
    items_room_revenue_with_board() {
      return [
        { text: this.$t("only_accommodation"), value: 0 },
        { text: this.$t("include_board"), value: 1 }
      ];
    }
  }
};
</script>
