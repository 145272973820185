<template>
  <div class="py-2">
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-0" outlined style="height: 100%;">
          <v-card-title>
            {{ $t("ranges_occupancy_screen_availability") }}
          </v-card-title>
          <v-card-text class="pb-0">
            <a-hotel-configuration-ranges-occ-availability
              :item="item"
            ></a-hotel-configuration-ranges-occ-availability>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="elevation-0" outlined style="height: 100%;">
          <v-card-title>
            {{ $t("pickup_intervals") }}
          </v-card-title>
          <v-card-text class="pb-0">
            <a-hotel-configuration-pickup-intervals
              :item="item"
            ></a-hotel-configuration-pickup-intervals>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="showSiteminder">
        <v-card class="elevation-0" outlined style="height: 100%;">
          <v-card-title>
            {{ $t("siteminder_connection") }}
          </v-card-title>
          <v-card-text class="pb-0">
            <a-hotel-configuration-siteminder
              :item="item"
            ></a-hotel-configuration-siteminder>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="showRmsProductionConfig">
        <v-card class="elevation-0" outlined style="height: 100%;">
          <v-card-title>
            {{ $t("revenue_configuration") }}
          </v-card-title>
          <v-card-text class="pb-0">
            <a-hotel-configuration-revenue
              :item="item"
            ></a-hotel-configuration-revenue>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import aHotelConfigurationPickupIntervals from "@/components/settings/aHotelConfigurationPickupIntervals.vue";
import aHotelConfigurationRangesOccAvailability from "@/components/settings/aHotelConfigurationRangesOccAvailability.vue";
import aHotelConfigurationSiteminder from "@/components/settings/aHotelConfigurationSiteminder.vue";
import aHotelConfigurationRevenue from "@/components/settings/aHotelConfigurationRevenue.vue";

export default {
  name: "a-hotel-configuration",
  components: {
    aHotelConfigurationPickupIntervals,
    aHotelConfigurationRangesOccAvailability,
    aHotelConfigurationSiteminder,
    aHotelConfigurationRevenue
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  },
  computed: {
    showSiteminder() {
      return (
        this.item &&
        this.item.settings &&
        this.item.settings.channel_manager_id === 1
      );
    },
    showRmsProductionConfig() {
      return (
        this.item &&
        this.item.permissions &&
        this.item.permissions.rms_production_config
      );
    }
  }
};
</script>
