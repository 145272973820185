<template>
  <div>
    <v-card-title class="pl-0">{{ $t("visible_sections") }}</v-card-title>
    <permissions-editor-section
      icon="mdi-home-outline"
      :available="available"
      :item="item"
      permission="dashboard"
      mandatory
      :single="is_hotel"
      :section_permissions="[
        'dashboard_rms',
        'dashboard_rms_hotelsdot',
        'dashboard_rate'
      ]"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-hand-coin-outline"
      :available="available"
      :item="item"
      permission="rms"
      :section_permissions="[
        'rms_strategy',
        'rms_strategy_grid',
        'rms_availability',
        'rms_year_sales',
        'rms_year_sales_grid',
        'rms_sales',
        'rms_segment',
        'rms_channel',
        'rms_roomtype',
        'rms_prices_evo',
        'rms_pricing',
        'rms_special_rates',
        'rms_offers',
        'rms_compare_prev_years',
        'rms_production_mode'
      ]"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-hand-coin-outline"
      :available="available"
      :item="item"
      permission="rms_hotelsdot"
      :section_permissions="[
        'rms_strategy_hotelsdot',
        'rms_availability_hotelsdot',
        'rms_sales_hotelsdot',
        'rms_segment_hotelsdot',
        'rms_channel_hotelsdot',
        'rms_roomtype_hotelsdot',
        'rms_prices_evo_hotelsdot',
        'rms_pricing_hotelsdot',
        'rms_special_rates_hotelsdot',
        'rms_offers_hotelsdot',
        'rms_compare_prev_years',
        'rms_production_mode'
      ]"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-hand-coin-outline"
      :available="available"
      :item="item"
      permission="rms_axel"
      :section_permissions="[
        'rms_strategy_axel',
        'rms_year_sales_axel',
        'rms_availability',
        'rms_sales',
        'rms_segment',
        'rms_channel',
        'rms_roomtype',
        'rms_pricing',
        'rms_compare_prev_years',
        'rms_production_mode'
      ]"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-hand-coin-outline"
      :available="available"
      :item="item"
      permission="rms_clicktotravel"
      :section_permissions="[
        'rms_strategy_clicktotravel',
        'rms_year_sales_clicktotravel',
        'rms_availability',
        'rms_sales',
        'rms_segment',
        'rms_channel',
        'rms_roomtype',
        'rms_pricing',
        'rms_compare_prev_years',
        'rms_production_mode',
        'rms_production_config'
      ]"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-chart-timeline-variant"
      :available="available"
      :item="item"
      permission="revenue"
      :section_permissions="['revenue_channel', 'revenue_pickup']"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-table-chair"
      :available="available"
      :item="item"
      permission="revenuerestaurant"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-wallet-bifold-outline"
      :available="available"
      :item="item"
      permission="expense"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-comment-multiple-outline"
      :available="available"
      :item="item"
      permission="reputation"
      :section_permissions="[
        'reputation_booking',
        'reputation_tripadvisor',
        'reputation_expedia',
        'reputation_googlehotelads',
        'reputation_tenedor',
        'reputation_survey'
      ]"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-bed-king-outline"
      :available="available"
      :item="item"
      permission="rate"
      :section_permissions="[
        'rate_web',
        'rate_channelmanager_tab',
        'parity',
        'channelmanager'
      ]"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-api"
      :available="available"
      :item="item"
      permission="connectapi"
      :section_permissions="['realtime']"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-abacus"
      :available="available"
      :item="item"
      permission="budget"
      :section_permissions="['budget_daily', 'budget_channels']"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-cog-outline"
      :available="available"
      :item="item"
      permission="settings"
      :section_permissions="[
        'customermanagement',
        'hotelmanagement',
        'accounting',
        'usermanagement',
        'trialmanagement',
        'requestsmanagement',
        'apistatus',
        'roomtypemanagement',
        'personal',
        'restaurantmanagement',
        'calendarmanagement',
        'eventsmanagement',
        'metrics',
        'revenue_import_manual',
        'daily_import_manual',
        'channel_import_manual',
        'reservations_import_manual',
        'revenue_import_launch',
        'segmentschannels'
      ]"
      :superuser_permissions="[
        'customermanagement',
        'trialmanagement',
        'requestsmanagement',
        'apistatus'
      ]"
    ></permissions-editor-section>
    <permissions-editor-section
      icon="mdi-tools"
      :available="available"
      :item="item"
      permission="tools"
      :section_permissions="[
        'highlights',
        'export_pdf',
        'veetal_assistant',
        'automations'
      ]"
    ></permissions-editor-section>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import PermissionsEditorSection from "./PermissionsEditorSection.vue";

export default {
  components: {
    PermissionsEditorSection
  },
  data: () => ({}),
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    },
    all_available: {
      type: Boolean,
      default: false
    }
  },
  filters: {},
  watch: {},
  methods: {},
  created() {},
  computed: {
    available() {
      let available_permissions = {};
      if (this.all_available) {
        for (const permission of Object.keys(this.available_permissions)) {
          available_permissions[permission] = true;
        }
      } else {
        available_permissions = this.available_permissions;
      }
      return available_permissions;
    },
    is_hotel() {
      return this.item.type === "hotel";
    },
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      available_permissions: "getAvailablePermissions"
    })
  }
};
</script>
